import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useMetaMask from "../../hooks/metamask";
import { Box, Popover, Typography, Button } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';


import { ConnectModal } from "../ConnectModal";
import styles from ".//Header.module.scss";
import { fontWeight } from '@mui/system';
import { useAuth } from "../../hooks/useAuth";

const Header = ({ onConnect, onDisconnect, address }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  useEffect(() => {
    if (isHeaderOpen) {
      document.body.style = 'overflow: hidden'
    } else {
      document.body.style = 'overflow: auto'
    }
  }, [isHeaderOpen]);

  const headerClass = () => {
    let cls = styles.header;
    if (isHeaderOpen) {
      cls += ' ' + styles.responsive;
    }
    return cls;
  };
  return (
    <header className={headerClass()}>
      <div className={styles.wrapper}>
        <Link onClick={() => { setIsHeaderOpen(false) }} to="/" className={`${styles.link} ${styles.logo}`}>
          <img src="/images/headerLogo.svg" alt="logo" />
        </Link>
        <a onClick={() => { setIsHeaderOpen(false) }} className={styles.link} href="/#story">
          Story
        </a>
        <a onClick={() => { setIsHeaderOpen(false) }} className={styles.link} href="/#metaFluencer">
          The MetaFluencer`s
        </a>
        <a onClick={() => { setIsHeaderOpen(false) }} className={styles.link} href="/#benefits">
          Benefits
        </a>
        <a onClick={() => { setIsHeaderOpen(false) }} className={styles.link} href="/#eco">
          Eco
        </a>
        <a onClick={() => { setIsHeaderOpen(false); }} className={styles.link} href="/#team">
          Team
        </a>
        <Link onClick={() => { setIsHeaderOpen(false) }} className={styles.link} to="/auction">
          Auction
        </Link>
        <Link onClick={() => { setIsHeaderOpen(false) }} className={styles.link} to="/contacts">
          Contacts
        </Link>
        <Link onClick={() => { setIsHeaderOpen(false) }} className={styles.link} to="/faq">
          FAQ
        </Link>
        <a onClick={() => { setIsHeaderOpen(false) }} className={styles.link} href="/#whitepaper">
          Whitepaper
        </a>
        {address ? (
          <div className={`${styles.popover}`}>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <Button className={styles.link} sx={{
                    lineHeight: "10px",
                    bgcolor: "transparent !important",
                    fontSize: "16px",
                    fontWeight: "400",
                    flexDirection: 'column',
                    justifyItems: "flex-start",
                    alignItems: "flex-start",
                    border: 'none',
                    boxShadow: 'none',
                    padding: '0'
                  }}
                    variant="contained" {...bindTrigger(popupState)}>
                    <Typography sx={{ fontSize: "18px" }} >{address.slice(0, 10)}...</Typography>
                    <Typography sx={{ fontSize: "12px", color: "#F900E7" }} >Wallet Connected</Typography>
                  </Button>

                  <Popover
                    sx={{
                      width: '100%',
                      borderRadius: '0'
                    }}
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',

                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box className={styles.dropdown} sx={{ width: '130px', display: "flex", flexDirection: "column", background: "#000" }}>
                      <Button onClick={() => onDisconnect()} sx={{ py: 1, px: 2 }}>
                        <Typography sx={{ color: "white" }}>Log Out</Typography>
                      </Button>
                    </Box>


                  </Popover>
                </div>
              )}
            </PopupState>
          </div>
        )
          : <button
            onClick={async () => {
              onConnect();
              setIsHeaderOpen(false);
            }}
            className={styles.button}
          >
            CONNECT WALLET
          </button>}
        <img onClick={() => { setIsHeaderOpen(true) }} className={styles.burger} src="/images/burger.svg" alt="burger" />
        <img onClick={() => { setIsHeaderOpen(false) }} className={styles.cross} src="/images/close.svg" alt="cross" />
        <ConnectModal
          isOpen={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
          }}
        />
      </div>
    </header>
  );
};
export default Header;