import { style } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";

import { Container } from "..";
import axiosInstance from "../../api/agent";
import styles from "./Team.module.scss";

const Team = () => {
  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    axiosInstance
      .get('/team')
      .then((data) => {
        setTeamData(data.data.teamMembers);
      })
      .catch(() => {
        setTeamData([]);
      });

    return;
  }, []);

  return (
    <Container id="team" className={styles.container}>
      <img className={styles.bg} src="/images/smoke2.png" alt="background" />
      <div className={styles.teamContainer}>
        <h2 className={styles.topTeam}>Team</h2>
      </div>
      <div className={styles.teamWrapper}>
        {teamData?.map(teamMember => {
          return (
            <div className={styles.teamCardContainer}>
              <div className={styles.avaContainer}>
                <img className={styles.ava} src={teamMember.picture} alt="ava" />
              </div>
              <h3 className={styles.fouder}>{teamMember.position}</h3>
              <p className={styles.name}>{teamMember.name}</p>
              <div className={styles.socialsContainar}>
                {teamMember.linkedin ? (
                  <div className={styles.iconContainer}>
                    <a target='_blank' href={teamMember.linkedin}>
                      <img className={styles.icon} src="images/in.svg" alt="in" />
                      <img
                        className={styles.iconHover}
                        src="/images/inHover.svg"
                        alt="in"
                      />
                      <img
                        className={styles.iconPress}
                        src="/images/inPress.svg"
                        alt="in"
                      />
                    </a>
                  </div>
                ) : null}
                {teamMember.instagram ? (
                  <div className={styles.iconContainer}>
                    <a target='_blank' href={teamMember.instagram}>
                      <img className={styles.icon} src="images/inst.svg" alt="inst" />
                      <img
                        className={styles.iconHover}
                        src="/images/instHover.svg"
                        alt="inst"
                      />
                      <img
                        className={styles.iconPress}
                        src="/images/instPress.svg"
                        alt="inst"
                      />
                    </a>
                  </div>
                ) : null}
                {teamMember.twitter ? (
                  <div className={styles.iconContainer}>
                    <a target='_blank' href={teamMember.twitter}>
                      <img
                        className={styles.icon}
                        src="images/twitter.svg"
                        alt="twitter"
                      />
                      <img
                        className={styles.iconHover}
                        src="/images/twitterHover.svg"
                        alt="twitter"
                      />
                      <img
                        className={styles.iconPress}
                        src="/images/twitterPress.svg"
                        alt="twitter"
                      />
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          )
        }) || null}
      </div >
    </Container >
  );
};
export default Team;
