import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

import axiosInstance from "../../api/agent";
import { Container, Question } from "../../components";
import styles from "./FAQ.module.scss";

const FAQ = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [faqData, setFaqData] = useState(null);

  useEffect(() => {
    axiosInstance
      .get("faq")
      .then((data) => {
        // debugger
        setFaqData(data.data.questions);
      })
      .catch(() => {
        setFaqData([]);
      });

      return;
  }, []);

  return (
    <Container className={styles.page}>
      <img className={styles.bg} src="/images/smoke2.png" alt="background" />
      <h1 className={styles.heading}>FAQ</h1>
      <div className={styles.questions}>
        {faqData?.length > 0
          ? faqData.map((el, i) => {
              return (
                <Question
                  key={el.id}
                  id={`question${i}`}
                  question={el.question}
                  answer={el.answer}
                />
              );
            })
          : <div className={styles.loader}>
          <CircularProgress />
        </div>}
        {faqData?.length === 0 ? (
          <p className={styles.nothing}>Nothing here</p>
        ) : null}
      </div>
    </Container>
  );
};

export default FAQ;
