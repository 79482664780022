import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { Layout, LotModal } from "./components";
import ScrollToTop from "./hoc/scrollToTop";
import { useAuth } from "./hooks/useAuth";
import { useWeb3 } from "./hooks/useWeb3";
import { Auction, Contacts, FAQ, Main } from "./pages";

function App() {
  const { address, connect, disconnect, sendTransaction } = useWeb3();
  const { login, logout, agent } = useAuth();

  const [notifications, setNotifications] = useState([]);

  const getNotifications = async (address) => {
    const jwt = await login(address);

    if (jwt) {
      try { 
        const { data } = await agent.get('/auction/notifications', {
          headers: {
            'Authorization': jwt
          }
        });

        setNotifications(data.auctionNotifications);
      } catch { }
    }
  }

  const onNotificationClose = (notificationId) => {
    setNotifications(value => {
      const nots = [...value];

      const index = nots.findIndex(not => not.id = notificationId);
      nots.splice(index, 1);

      return nots;
    })
  }

  useEffect(() => {
    getNotifications(address);
    return;
  }, [address])

  return (
    <Layout address={address} connect={connect} disconnect={disconnect} sendTransaction={sendTransaction} login={login} logout={logout}>
      {notifications?.length ? <LotModal key={notifications[0].id} login={login} address={address} onClose={() => onNotificationClose(notifications[0].id)} isNotification={true} isOpen={true} name={notifications[0].auctionItem.name} picture={notifications[0].auctionItem.picture} agent={agent} notificationId={notifications[0].id} /> : null}
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/auction" element={<Auction address={address} connect={connect} disconnect={disconnect} sendTransaction={sendTransaction} login={login} logout={logout} />} />
        </Routes>
      </ScrollToTop>
    </Layout>
  );
};

export default App;