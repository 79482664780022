import WalletConnectProvider from '@walletconnect/web3-provider'
import { providers, utils, Contract } from 'ethers'
import { useCallback, useEffect, useReducer, useState } from 'react'
import WalletLink from 'walletlink'
import Web3Modal from 'web3modal'

export function useWeb3() {
    const INFURA_ID = '460f40a260564ac4a4f4b3fffb032dad'

    const providerOptions = {
        walletconnect: {
            package: WalletConnectProvider, // required
            options: {
                infuraId: INFURA_ID, // required
            },
        },
        // 'custom-walletlink': {
        //     display: {
        //         logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
        //         name: 'Coinbase',
        //         description: 'Connect to Coinbase Wallet (not Coinbase App)',
        //     },
        //     options: {
        //         appName: 'Coinbase', // Your app name
        //         networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
        //         chainId: 1,
        //     },
        //     package: WalletLink,
        //     connector: async (_, options) => {
        //         const { appName, networkUrl, chainId } = options
        //         const walletLink = new WalletLink({
        //             appName,
        //         })
        //         const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
        //         await provider.enable()
        //         return provider
        //     },
        // }
    };

    let web3Modal
    if (typeof window !== 'undefined') {
        web3Modal = new Web3Modal({
            network: 'mainnet', // optional
            cacheProvider: true,
            providerOptions, // required
        });

        const web3Modals = document.querySelectorAll('#WEB3_CONNECT_MODAL_ID');
        const root = document.querySelector('#layout');
        for (const web3ModalEl of web3Modals) {
            web3ModalEl.classList.add('web3modal');
            for (const child of web3ModalEl.children) {
                child.onclick = () => web3Modal.connect();
            }
        }
    }

    const [state, setState] = useState({
        provider: null,
        web3Provider: null,
        address: null,
        chainId: null
    });

    const { provider, web3Provider, address, chainId } = state;

    const connect = useCallback(async function () {
        const provider = await web3Modal.connect();

        const web3Provider = new providers.Web3Provider(provider);

        const signer = web3Provider.getSigner();
        const address = await signer.getAddress();

        const network = await web3Provider.getNetwork();
        setState({
            provider,
            address,
            chainId: network.chainId,
            web3Provider
        });
    }, []);

    const sendTransaction = async (receiver, value) => {
        try {
            const contractAddress = "0xf2b2456fbbf42728ae9b1c2588397f52a5a85394";
            const contractAbiFragment = [
                {
                    "name": "transfer",
                    "type": "function",
                    "inputs": [
                        {
                            "name": "_to",
                            "type": "address"
                        },
                        {
                            "type": "uint256",
                            "name": "_tokens"
                        }
                    ],
                    "constant": false,
                    "outputs": [],
                    "payable": false
                }
            ];

            const signer = web3Provider.getSigner();

            const contract = new Contract(contractAddress, contractAbiFragment, signer);

            const numberOfDecimals = 18;
            const numberOfTokens = utils.parseUnits(value, numberOfDecimals);

            // const params = [{
            //     from: address,
            //     to: receiver,
            //     value: utils.parseUnits(String(value), 'ether').toHexString()
            // }];

            return await contract.transfer(receiver, numberOfTokens);
        } catch (ex) {
            console.log(ex);
            return null;
        }
    };

    const disconnect = useCallback(async function () {
        await web3Modal.clearCachedProvider();
        if (provider?.disconnect && typeof provider.disconnect == 'function') {
            await provider.disconnect();
        };

        setState({
            provider: null,
            web3Provider: null,
            address: null,
            chainId: null
        });
    }, [provider]);

    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connect();
        }
    }, [connect]);

    useEffect(() => {
        if (provider?.on) {
            const handleAccountsChanged = (accounts) => {
                setState({
                    address: accounts[0],
                    ...state
                });
            }

            // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
            const handleChainChanged = (_hexChainId) => {
                window.location.reload()
            }

            const handleDisconnect = (error) => {
                // eslint-disable-next-line no-console
                console.log('disconnect', error)
                disconnect()
            }

            provider.on('accountsChanged', handleAccountsChanged)
            provider.on('chainChanged', handleChainChanged)
            provider.on('disconnect', handleDisconnect)

            // Subscription Cleanup
            return () => {
                if (provider.removeListener) {
                    provider.removeListener('accountsChanged', handleAccountsChanged)
                    provider.removeListener('chainChanged', handleChainChanged)
                    provider.removeListener('disconnect', handleDisconnect)
                }
            }
        }
    }, [provider, disconnect]);

    return {
        provider,
        web3Provider,
        address,
        chainId,
        connect,
        disconnect,
        sendTransaction
    };
}