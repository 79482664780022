import React from "react";

import { Footer, Header } from "..";
import styles from "./Layout.module.scss";

const Layout = ({ children, address, connect, disconnect, login, logout }) => {
  const onConnect = async () => {
    await connect();
    if (address) {
      await login(address);
    }
  };

  const onDisconnect = async () => {
    await disconnect();
    logout(address);
  };

  return (
    <div className={styles.layout} id='layout'>
      <Header onConnect={onConnect} onDisconnect={onDisconnect} address={address} />
      <main>
        <div className={styles.content}>{children}</div>
      </main>
      <div className={styles.footer}>
      <Footer />
      </div>
    </div>
  );
};

export default Layout;
