import React from "react";

import { Container } from "..";
import styles from "./Story.module.scss";

const Story = () => {
  return (
    <Container className={styles.container} id="story">
      <div className={styles.story}>
        <div className={styles.topStoryContainer}>
          <h2 className={styles.topStory}>The Story</h2>
        </div>
        <div className={styles.pena}>
          <img className={styles.ship} src="images/ship.png" alt="ship" />
          <p className={styles.textStory}>
            Meta Fluencer is a futuristic character from the Metaverse, a planet beyond the stars. She has traveled back from the future to 2022 to save the human species. Meta Fluencer’s are creative, brave, and stubborn characters who will do their utmost to save humanity, guiding them towards making the best decisions — failure could have a catastrophic effect on humanity in the Metaverse.
          </p>
        </div>
      </div>
    </Container>
  );
};
export default Story;
