import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import defaultAgent from '../api/agent';

export function useAuth() {
    const [{ user, agent }, setState] = useState({
        user: null,
        agent: defaultAgent
    });

    const login = useCallback(async function (address) {
        try {
            const { data: { jwt } } = await agent.post('/auth', {
                address
            });

            localStorage.setItem('x-auth-token', jwt);

            const { data: { user: me } } = await agent.get('/auth/me', {
                headers: {
                    'Authorization': jwt
                }
            });

            setState({
                user: me,
                agent: axios.create({
                    headers: {
                        'Authorization': jwt
                    },
                    baseURL: 'https://api.meta-fluencer.com/'
                })
            });

            return jwt;
        } catch {
            return null;
        }
    }, []);

    const logout = useCallback(function () {
        try {
            localStorage.removeItem('x-auth-token');

            agent.defaults.headers = {};

            setState({
                user: null,
                agent: agent
            });

            return true;
        } catch {
            return false;
        }
    }, []);



    return {
        agent,
        user,
        login,
        logout
    };
}