import styles from './Fields.module.scss'
import styled from "@emotion/styled";
import React from "react";

import customStyles from './data.js'

const StyledInput = styled("input")(() => customStyles);

const Input = ({ label, className, value, onChange, error, ...restProps }) => {
  return (
    <div className={styles.textFieldContainer  + `${className ? ' ' + className : ''}` + `${error ? ' ' + styles.textFieldError : ''}`}>
      <p className={styles.fieldLabel}>{label + '*'}</p>
      <StyledInput
        className={styles.textField}
        error={true}
        placeholder={label}
        value={value}
        onChange={onChange}
        {...restProps}
      />
    </div>
  );
};

export default Input;
