import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/agent";

import { Container, LotModal } from "../../components";
import { AuctionLot } from "../../components";
import styles from "./Auction.module.scss";

const Auction = ({ children, address, connect, disconnect, login, logout, sendTransaction }) => {
  const [auctionData, setAuctionData] = useState(null);
  const [page, setPage] = useState(1);
  const [isThereMore, setIsThereMore] = useState(false);
  const limit = 10;

  const getItems = async () => {
    try {
      const url = `/auction/items?limit=${limit}&page=${page}`;
      const { data } = await axiosInstance.get(url);
      setAuctionData(items => {
        if (!items) {
          return data.auctionItems;
        }
        const clonedItems = [...items];
        clonedItems.push(...data.auctionItems);
        return clonedItems;
      });
      console.log(auctionData);
      setIsThereMore(data.auctionItems.length == limit);
      setPage(value => value + 1);

    } catch {
      setAuctionData([]);
    }
  }

  useEffect(() => {
    getItems()
    return;
  }, []);

  return (
    <Container className={styles.container}>
      <img className={styles.bg} src="/images/smoke2.png" alt="background" />
      <h1 className={styles.heading}>Auction</h1>
      <div className={styles.lots}>
        {auctionData && auctionData.length ? auctionData.map((i) => {
          const sortedBids = i.auctionBids.sort((a, b) => Number(a.amount) < Number(b.amount) ? 1 : -1);
          const latest = sortedBids[0];

          const amount = Number(latest?.amount || i.floorPrice);

          const createdAt = new Date(i.createdAt);
          const expirationDate = ((createdAt.getTime() / 1000) + i.expiration) * 1000;

          return (
            <AuctionLot
              name={i.name}
              amount={amount}
              picture={i.picture}
              description={i.description}
              key={i.id}
              expirationDate={expirationDate}
              bids={sortedBids}
              iid={i.id}
              address={address} connect={connect} disconnect={disconnect} sendTransaction={sendTransaction} login={login} logout={logout}
            />
          );
        }) : <h2>Nothing here</h2>}
      </div>
      {isThereMore ? <div className={styles.containerButton}>
        <button onClick={getItems} className={styles.button}>
          Show more
          <img src="/images/rocketWhite.svg" alt="rocket" />
        </button>
      </div> : null}
    </Container>
  );
};

export default Auction;
