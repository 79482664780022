import styles from "./AuctionLot.module.scss";
import React, { useCallback, useState } from "react";
import { LotModal } from "../LotModal";

const AuctionLot = ({ amount, name, picture, description, expirationDate, bids, iid, children, address, connect, disconnect, login, logout, sendTransaction }) => {
  const [isOpenModal, setisOpenModal] = useState(false);

  const onClick = useCallback(() => {
    if (address) {
      setisOpenModal(true);
    } else {
      connect();
    }
  }, [address]);
  return (
    <>
      <div className={styles.container}>
        <img className={styles.lotImage} src={picture} alt="lot" />
        <div className={styles.lotInfo}>
          <h6 className={styles.lotName}>
            {name}
          </h6>
          <p className={styles.lotPrice}>
            {amount} $ILONSI
          </p>
          <button onClick={onClick} className={styles.button}>Bid</button>
        </div>
      </div>
      <LotModal address={address} connect={connect} disconnect={disconnect} sendTransaction={sendTransaction} login={login} logout={logout} iid={iid} bids={bids} amount={amount} name={name} picture={picture} description={description} expirationDate={expirationDate} isOpen={isOpenModal} onClose={() => setisOpenModal(false)} />
    </>
  );
};

export default AuctionLot;
