import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import MyRegexp from "myregexp";
import React, { useState } from "react";
import * as Yup from "yup";

import axiosInstance from "../../api/agent";
import { Container } from "../../components";
import Input from "../../components/Fields/Input";
import TextArea from "../../components/Fields/TextArea";
import styles from "./Contacts.module.scss";

const initialValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
};

const Contacts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    type: "success",
    isOpen: false,
    message: null,
  });

  const handleClose = () => {
    setMessage((prev) => {
      return { ...prev, isOpen: false };
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const newValues = { ...values };
      newValues.phone = newValues.phone.replace(/[^\d]/g, "");
      newValues.email = newValues.email.toLowerCase();
      axiosInstance
        .post("messages", newValues)
        .then(() => {
          formik.resetForm();
          setMessage({
            isOpen: true,
            type: "success",
            message: "We will reach you soon",
          });
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setMessage((prev) => ({
              ...prev,
              message: "Please check your information and try again",
            }));
          } else {
            setMessage((prev) => ({
              ...prev,
              message: "Something went wrong",
            }));
          }
          setMessage((prev) => ({
            ...prev,
            isOpen: true,
            type: "error",
          }));
          setIsLoading(false);
        });
    },
  });

  return (
    <Container className={styles.container}>
      <img className={styles.bg} src="/images/smoke2.png" alt="background" />
      <h1 className={styles.heading}>Contact Us</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.textFields}>
          <Input
            onChange={formik.handleChange}
            value={formik.values.name}
            name="name"
            label={"Your Name"}
            error={formik.touched.name && Boolean(formik.errors.name)}
            className={styles.textFieldContainer}
          />
          <Input
            value={formik.values.phone}
            onChange={formik.handleChange}
            name="phone"
            label={"Phone Number"}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            className={styles.textFieldContainer}
          />
          <Input
            onChange={formik.handleChange}
            value={formik.values.email}
            label={"Email Address"}
            name="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            className={styles.textFieldContainer}
          />
        </div>
        <TextArea
          name="message"
          onChange={formik.handleChange}
          value={formik.values.message}
          label={"Your Message"}
          error={formik.touched.message && Boolean(formik.errors.message)}
        />
        {/* {successMessage ? <p className={styles.successMessage}>{successMessage}</p> : null}
        {errorMessage ? <p className={styles.errorMessage}>{errorMessage}</p> : null} */}
        <Snackbar
          open={message.isOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={message.type}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message.message}
          </Alert>
        </Snackbar>
        {isLoading ? (
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.containerButton}>
            <button type="submit" className={styles.button}>
              Send
            </button>
          </div>
        )}
      </form>
    </Container>
  );
};

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required field")
    .min(1, "Minimum length - 1")
    .max(150, "Maximum length - 150"),
  phone: Yup.string()
    .required("Required field")
    .matches(MyRegexp.phone(), "Enter a valid phone number"),
  email: Yup.string()
    .required("Required field")
    .max(150, "Maximum length - 150")
    .matches(MyRegexp.email(), "Enter a valid email address"),
  message: Yup.string()
    .required("Required field")
    .min(1, "Minimum length - 1")
    .max(1024, "Maximum length - 1024"),
});

export default Contacts;
