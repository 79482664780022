import React from "react";

import { Container } from "../Container";
import styles from "./Smartcontacts.module.scss";

const Smartcontacts = () => {
  return (
    <Container >
      <div className={styles.smartContainer}>
        <h2 className={styles.topSmart}>ILONSI’s Smart Contracts</h2>
      </div>
      <div className={styles.contactCardContainer}>
        <div className={styles.contactCard}>
          <div className={styles.card}>
            <img className={styles.logo} src="/images/founderlogo.svg" alt="logo" />
            <h3 className={styles.name}>Opensea</h3>
            <a href="https://opensea.io/collection/metafluencer" target="_blank">
              <button className={styles.button}>Fly</button>
            </a>
          </div>
        </div>
        <div className={styles.contactCard}>
          <div className={styles.card}>
            <img className={styles.logo} src="/images/uniswap.png" alt="logo" />
            <h3 className={styles.name}>UniSwap</h3>
            <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf2b2456fbbf42728ae9b1c2588397f52a5a85394&use=V2" target="_blank">
              <button className={styles.button}>Fly</button>
            </a>
          </div>
        </div>
        <div className={styles.contactCard}>
          <div className={styles.card}>
            <img className={styles.logo} src="/images/etherscan.png" alt="logo" />
            <h3 className={styles.name}>Etherscan</h3>
            <a href="https://etherscan.io/token/0xf2b2456fbbf42728ae9b1c2588397f52a5a85394" target="_blank">
              <button className={styles.button}>Fly</button>
            </a>
          </div>
        </div>
      </div>

    </Container>
  );
};
export default Smartcontacts;
