const customStyles = `
  background-color: #c6c6c656;
  width: 100%;
  padding: 16px 42px;
  border: double 1px transparent;
  border-radius: 30px;
  background-image: linear-gradient(#100d10, #100d10),
    linear-gradient(170deg, #fff 0%, #100d10 70%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  color: white;
  box-sizing: border-box;

  &::placeholder {
    color: #292629;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
  }

  &:focus {
    background-image: linear-gradient(#100d10, #100d10),
    linear-gradient(170deg, #fff 0%, #fff 100%);
  }
`;
export default customStyles