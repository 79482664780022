import React from "react";

import { Container } from "..";
import styles from "./Eco.module.scss";

const Eco = () => {
  return (
    <Container id="eco" className={styles.container}>
      <div className={styles.topEcoContainer}>
        <h2 className={styles.topEco}>Eco</h2>
      </div>
      <div className={styles.card}>
        <div className={styles.textContainer}>
          <h3 className={styles.topText}>META CHARITY</h3>
          <p className={styles.textEco}>
            We will donate 20% of all Meta Fluencer NFT sales and 20% of all second-hand royalty sales to support women and children who have experienced trauma, through various organizations. It is our belief that everyone can do something to help make the world a better place, and we are committed to do our part to create a just, equitable, and freer society.
          </p>
        </div>
        <div className={styles.containerImg}>
          <img className={styles.ecoImg} src="images/arms.png" alt="icon" />
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.textContainer}>
          <h3 className={styles.topText}>RENTING SYSTEM</h3>
          <p className={styles.textEco}>
            All Meta Fluencer holders have the option to start one shop at ILONSI Shop, however, they may choose to rent out their shop to a company or influencer in return for sales commissions. Meta Fluencer holders enjoy perks like lifetime membership and unlimited product publishing rights. These perks increase Meta Fluencer valuation, which in turn increase demand.
          </p>
        </div>
        <div className={styles.containerImg}>
          <img className={styles.ecoImg} src="images/renting.jpg" alt="icon" />
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.textContainer}>
          <h3 className={styles.topText}>ILONSI SHOP</h3>
          <p className={styles.textEco}>
            The ILONSI family will launch ILONSI Shop, a decentralized marketplace which is a combination of a drop shipping and an affiliate system. ILONSI Shop is a safe platform for consumers to buy cost-effective, quality textile garments from international companies, in line with the Swedish Consumer Purchase Act.
          </p>
        </div>
        <div className={styles.containerImg}>
          <img className={styles.ecoImg} src="images/shop.jpg" alt="icon" />
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.textContainer}>
          <h3 className={styles.topText}>$ILONSI TOKEN</h3>
          <p className={styles.textEco}>
            Token launch will occur at a later date and will be a part of the ILONSI Shop and the Meta Fluencers ecosystem. Our aim is for ILONSI tokens to be used primarily by brands to "stake" their products visibility at ILONSI SHOP for influencers and as a mechanism to pay commissions to companies for sales made by influencers through ILONSI Shop. ILONSI tokens will also be used later on at Meta-Fluncer.com to buy and bid on various products, NFTs and make payments.          </p>
        </div>
        <div className={styles.containerImg}>
          <img className={styles.ecoImg} src="images/token.gif" alt="icon" />
        </div>
      </div>
    </Container>
  );
};
export default Eco;
