import { Alert, Box, Dialog, DialogContent, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import agent from '../../api/agent';

import styles from "./LotModal.module.scss";

function MainContext({ onClose, setContext, name, description, expirationDate, picture, amount, bids, iid, address, connect, login, logout, sendTransaction }) {
  const [bidAmount, setBidAmount] = useState('');
  const [message, setMessage] = useState({
    type: "success",
    isOpen: false,
    message: null,
  });

  if (!address) {
    connect();
  }

  const onBid = async () => {
    let jwt;
    try {
      if (Number(bidAmount) <= Number(amount)) {
        throw new Error('Bid amount is less than the floor price')
      }

      if (bidAmount == '') {
        throw new Error('Bid amount is required')
      }

      if (address) {
        jwt = await login(address);
      } else {
        throw new Error('Wallet is not connected');
      }

      const { data: { auctionBid } } = await agent.post('/auction/items/bids', {
        amount: Number(bidAmount),
        auctionItemId: iid
      }, {
        headers: {
          'Authorization': jwt
        }
      });

      const { data: { auctionWallet } } = await agent.get(`/auction/wallets/${auctionBid.auctionWalletId}`, {
        headers: {
          'Authorization': jwt
        }
      });

      const hash = await sendTransaction(auctionWallet.public, bidAmount);
      if (!hash) {
        throw new Error('Error upon sending transaction');
      }

      setContext("submit");
    } catch (ex) {
      console.log(ex);
      setMessage({
        isOpen: true,
        type: 'error',
        message: ex?.message || 'Unknown error occurred. Please try later'
      });

      console.log(message);
    }
  };

  return address ? (
    <>
      <Box className={styles.modal}>
        <div className={styles.container}>
          <img
            onClick={onClose}
            className={styles.cross}
            src="/images/close.svg"
            alt="cross"
          />
          <h3 className={styles.heading}>
            {name}
          </h3>
          <div className={styles.containerImg}>
            <img className={styles.img} src={picture} alt="lot" />
          </div>
          <div className={styles.timeContainer}>
            <Countdown
              date={expirationDate}
              intervalDelay={0}
              precision={3}
              renderer={props => {
                return (
                  <>
                    <div className={styles.timeItem}>
                      <h6 className={styles.timeValue}>{props.days}</h6>
                      <p className={styles.timeType}>Days</p>
                    </div>
                    <div className={styles.timeItem}>
                      <h6 className={styles.timeValue}>{props.hours}</h6>
                      <p className={styles.timeType}>Hours</p>
                    </div>
                    <div className={styles.timeItem}>
                      <h6 className={styles.timeValue}>{props.minutes}</h6>
                      <p className={styles.timeType}>Minutes</p>
                    </div>
                    <div className={styles.timeItem}>
                      <h6 className={styles.timeValue}>{props.seconds}</h6>
                      <p className={styles.timeType}>Seconds</p>
                    </div>
                  </>
                )
              }
              }
            />
          </div>
          <p className={styles.description}>
            {description}
          </p>
          <div className={styles.floorPriceGradient}>
            <div className={styles.floorPriceContainer}>
              <span className={styles.floorPrice}>FLOORPRICE:&nbsp;</span>
              <span className={styles.floorPriceValue}>{amount} $ILONSI</span>
            </div>
          </div>
          <button onClick={() => {
            setContext("bid");
          }} className={styles.bids}>{`[ ${bids?.length || 0} bids ]`}</button>
          <input
            type='number'
            placeholder='Bid Amount'
            value={bidAmount}
            onChange={e => setBidAmount(e.target.value)}
            min={amount}
            className={styles.bidAmount}
          />
          <p className={styles.bidQuantity}>Bid {amount} $ILONSI or more</p>
          <div className={styles.actions}>
            <button
              onClick={(async () => {
                await onBid();
              })}
              className={styles.submit}
            >
              Submit BID
            </button>
            {/* <div className={styles.containerClose}>
            <div className={styles.containerClose2}>
              <button onClick={onClose} className={styles.close}>
                You have BID
              </button>
            </div>
          </div>  */}
          </div>
        </div>
      </Box>
      <Snackbar
        open={message.isOpen}
        autoHideDuration={6000}
        onClose={() => setMessage({
          isOpen: false,
          message: null,
        })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setMessage({
            isOpen: false,
            message: null,
          })}
          severity={message.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </>
  ) : null;
}

const BidContext = ({ onClose, setContext, bids }) => {
  return (
    <Box className={styles.containerBid}>
      <div className={styles.actions}>
        <img
          onClick={() => {
            setContext("main");
          }}
          className={styles.back}
          src="/images/back.svg"
          alt="back"
        />
        <img
          onClick={onClose}
          className={styles.cross}
          src="/images/close.svg"
          alt="cross"
        />
      </div>
      <div className={styles.containerBid}>
        {bids?.length ? bids.map((bid) => {
          return (
            <div className={styles.itemContainer}>
              <div className={styles.icon}></div>
              <div className={styles.bidContent}>
                <p className={styles.name}>{Number(bid.amount)} $ILONSI</p>
                <div className={styles.bidInfo}>
                  <p className={styles.by}>by</p>
                  <p className={styles.text}>{bid.user.address.slice(0, 10)}...</p>
                </div>
              </div>
            </div>
          )
        }) : (
          <div className={styles.nothingHere}>
            <h1>Nothing here yet</h1>
          </div>
        )}
      </div>
    </Box>
  );
};

const SubmitContext = ({ onClose, picture, amount }) => {
  return (
    <Box className={styles.modal}>
      <div className={styles.container}>
        <img
          onClick={onClose}
          className={styles.cross}
          src="/images/close.svg"
          alt="cross"
        />
        <h3 className={styles.heading}>{amount} $ILONSI</h3>
        <div className={styles.containerImg}>
          <img className={styles.img} src={picture} alt="lot" />
        </div>
        <p className={styles.description}>
          You have bid {amount} on this lot. Please wait for a couple of minutes before the payment is confirmed.
        </p>
        <button onClick={onClose} className={styles.submit}>
          Close
        </button>
      </div>
    </Box>
  );
};

const NotificationContext = ({ onClose, picture, name, agent, notificationId, address, login }) => {
  const onSetSeen = async () => {
    try {
      const jwt = await login(address)
      await agent.put(`/auction/notifications/set-seen/${notificationId}`, {}, {
        headers: {
          'Authorization': jwt
        }
      });
      onClose();
    } catch(err) {
      console.log(err)
      onClose();
    }
  }
  
  return (
    <Box className={styles.modal}>
      <div className={styles.container}>
        <img
          onClick={onSetSeen}
          className={styles.cross}
          src="/images/close.svg"
          alt="cross"
        />
        <h3 className={styles.heading}>Congratulations!</h3>
        <div className={styles.containerImg}>
          <img className={styles.img} src={picture} alt="lot" />
        </div>
        <p className={styles.description}>
          Your bid has won you {name}. Congratulations! You will receive NFT or, in case it's a physical item, $ILONSI token equivalent.
        </p>
        <button onClick={onSetSeen} className={styles.submit}>
          Close
        </button>
      </div>
    </Box>
  );
};


// const SubmitContext = ({ onClose }) => {
//   return (
//     <Box className={styles.modal}>
//       <img
//         onClick={onClose}
//         className={styles.cross}
//         src="/images/close.svg"
//         alt="cross"
//       />
//       <div className={styles.containerSubmit}>
//         <h3 className={styles.heading}>100 $ILONSI</h3>
//         <div className={styles.containerImg}>
//           <img className={styles.img} src="/images/lotImage.png" alt="lot" />
//         </div>
//         <button onClick={onClose} className={styles.submit}>
//           Subim BID
//         </button>
//       </div>
//     </Box>
//   );
// };

const switchContext = (context, setContext, onClose, amount, name, picture, description, expirationDate, bids, iid, address, connect, login, logout, sendTransaction, notificationId) => {
  switch (context) {
    case "bid":
      return <BidContext bids={bids} setContext={setContext} onClose={onClose} />;
    case "submit":
      return <SubmitContext amount={amount} picture={picture} onClose={onClose} setContext={setContext} />;
    case "notification":
      return <NotificationContext address={address} login={login} agent={agent} picture={picture} name={name} onClose={onClose} setContext={setContext} notificationId={notificationId} />;
    default:
      return <MainContext address={address} connect={connect} sendTransaction={sendTransaction} login={login} logout={logout} iid={iid} bids={bids} amount={amount} name={name} picture={picture} description={description} expirationDate={expirationDate} onClose={onClose} setContext={setContext} />;
  }
};

const LotModal = ({ isNotification, isOpen, onClose, amount, name, picture, description, expirationDate, bids, iid, address, connect, login, logout, sendTransaction, notificationId, agent }) => {
  const [context, setContext] = useState("main");

  useEffect(() => {
    if (isOpen) {
      setContext(isNotification ? "notification" : "main");
    }
  }, [isOpen]);

  const classes = {
    paper: styles.paper,
  };

  return (
    <Dialog
      classes={classes}
      maxWidth="md"
      fullWidth
      scroll="body"
      open={isOpen}
      onClose={onClose}
    >
      {switchContext(context, setContext, onClose, amount, name, picture, description, expirationDate, bids, iid, address, connect, login, logout, sendTransaction, notificationId, agent)}
    </Dialog>
  );
};

export default LotModal;
