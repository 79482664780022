import { Box, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import useMetaMask from "../../hooks/metamask";
import agent from '../../api/agent';

import styles from "./ConnectModal.module.scss";

function ConnectContext({ onClose, setContext, onConnect }) {
  return (
    <Box className={styles.modal}>
      <div className={styles.container}>
        <img
          onClick={onClose}
          className={styles.cross}
          src="/images/close.svg"
          alt="cross"
        />
        <h3 className={styles.heading}>Welcome!</h3>
        <p className={styles.subtitle}>
          Let’s begin with connecting your MetaMask
        </p>
        <button onClick={onConnect} className={styles.buttonConnect}>
          Connect
        </button>
      </div>
    </Box>
  );
}

const switchContext = (context, setContext, onClose, onConnect) => {
  return <ConnectContext setContext={setContext} onClose={onClose} onConnect={onConnect} />;
};

const ConnectModal = ({ isOpen, onClose }) => {
  const [context, setContext] = useState("main");
  const { connect, disconnect, isActive, account } = useMetaMask();

  const auth = async () => {
    try {
      await agent.post('/auth', {
        address: account
      });
    } catch { }
  }

  useEffect(() => {
    if (isOpen) {
      setContext("main");
    }
  }, [isOpen]);

  useEffect(() => {
    console.log('isActive', isActive)

    onClose();
    return null;
  }, [isActive]);

  useEffect(() => {
    if (account) {
      auth();
    }
    return null;
  }, [account]);


  const classes = {
    paper: context === "connect" ? styles.connect : styles.paper,
  };

  return (
    <Dialog
      classes={classes}
      maxWidth="sm"
      fullWidth
      scroll="body"
      open={isOpen}
      onClose={onClose}
    >
      {switchContext(context, setContext, onClose, connect)}
    </Dialog>
  );
};

export default ConnectModal;
